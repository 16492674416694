import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MealIndex from "../components/MealIndex"

const MealIndexPage = () => (
  <Layout page="meal-index">
    <SEO title="Meal Index" />
    <MealIndex></MealIndex>
  </Layout>
)

export default MealIndexPage
