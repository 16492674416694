import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Div100vh from "react-div-100vh"

const NavContainer = styled.nav`
  padding: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  /* margin-top: 200px; */
  width: 100%;
  ${breakpoint("mobile")`
  padding: 1rem;

  `}
  ${breakpoint("tablet")`
  padding: 8rem;

  `}
  /* background: gray; */
  ${props =>
    props.hovering &&
    `
       /* background: black; */
      `}
  a {
    ${breakpoint("mobile")`
 display: block;

  `}
  ${breakpoint("tablet")`
 display: inline;

  `}
    span {
      font-family: warnock-pro-display, serif;
      font-style: italic;
      font-weight: 200;
      color: black;
      /* text-decoration-style: wavy; */
      /* text-decoration-thickness: 1px; */
      /* text-decoration-width: 1px; */
      /* text-decoration: underline solid blue 1px; */
      text-decoration: none;
      display: inline-block;
      font-size: 1.4rem;
      padding: 0 1rem;
      line-height: 2;

      /* background: #000; */
      position: relative;
      z-index: 1;
      ${props =>
        props.hovering &&
        `
       opacity: 0;
      `}
      ${breakpoint("mobile")`
      font-size: 0.9rem;

  `}
      ${breakpoint("tablet")`
      font-size: 1.4rem;

  `}
    }
    &:hover {
      span {
        text-decoration: underline;
        text-decoration-style: wavy;
        /* background: #000; */
        color: red;
        ${props =>
          props.hovering &&
          `
       opacity: 1;
      `}
    }
      }
      img {
        display: block;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      z-index: 0;
      object-fit: cover;
      height: 100vh;
      /* opacity: 0.2; */
      filter: invert(100);
      /* mix-blend-mode: screen; */
      display: none;
    }
  }
`

const Background = styled.div`
  position: fixed;
  width: 640px;
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
`
const BackgroundContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  top: 0;
  svg {
    width: 70vw;
    height: 70vh;
    object-fit: contain;
    /* filter: invert(100); */
    position: relative;
    z-index: 10;
    opacity: 0.6;
    * {
      fill: white;
    }
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 9;
    /* filter: grayscale(100%) brightness(0.9) contrast(150%); */
  }
`

const MealIndex = () => {
  const [hovering, setHovering] = useState(false)
  const [hover, setHover] = useState(null)

  const data = useStaticQuery(graphql`
    query EventQuery {
      allWordpressWpEvent {
        edges {
          node {
            title
            path
            slug
            content
            acf {
              header_image {
                localFile {
                  publicURL
                }
              }
              video {
                localFile {
                  publicURL
                }
              }
              logo
              date
              sold_out
              past
              gallery {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  const wpData = data.allWordpressWpEvent.edges

  return (
    <>
      <Div100vh>
        <NavContainer hovering={hovering}>
          <div>
            {data.allWordpressWpEvent.edges.map((e, i) => {
              const index = i + 1
              return (
                <Link
                  to={e.node.path}
                  onMouseEnter={() => {
                    setHovering(true)
                    setHover(index)
                  }}
                  onMouseLeave={() => {
                    setHovering(false)
                    setHover(null)
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: e.node.title }}
                  ></span>
                </Link>
              )
            })}
          </div>
        </NavContainer>
      </Div100vh>
      {hover && (
        <EventBackground
          bgIndex={hover - 1}
          acf={wpData[hover - 1].node.acf}
        ></EventBackground>
      )}
    </>
  )
}

const EventBackground = ({ acf, bgIndex }) => {
  return (
    <Background>
      <BackgroundContent>
        {acf.video && (
          <video
            src={acf.video.localFile.publicURL}
            autoPlay={true}
            controls={false}
            muted={true}
            loop
          />
        )}
      </BackgroundContent>
    </Background>
  )
}

export default MealIndex
